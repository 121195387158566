import axios from "axios";

// const baseURL = "http://localhost:4000";
const baseURL = "https://rota-backend.onrender.com";

const axiosInstance = axios.create({
	timeout: 30000,
	baseURL,
});

export default axiosInstance;
