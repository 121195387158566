import React from "react";
import contourBg from "../../../assets/contour-bg-big.png";
import arrowDown from "../../../assets/arrow-down-icon.png";
import appLogo from "../../../assets/rota-logo-word.png";
import footStep from "../../../assets/footstep-icon.png";
import footStepGrey from "../../../assets/footstep-icon-grey.png";
import signalIcon from "../../../assets/signal-icon.png";
import markenIcon from "../../../assets/marker-icon.png";
import featureOne from "../../../assets/feature-one-desktop.png";
import featureTwo from "../../../assets/feature-two-desktop.png";
import featureThree from "../../../assets/feature-three-desktop.png";

import "./index.css";

export default function Home() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				overflowX: "hidden",
			}}
		>
			<img
				src={contourBg}
				alt="rota"
				style={{
					width: "100%",
					height: "100%",
					position: "fixed",
					top: 0,
					opacity: 1,
					pointerEvents: "none",
				}}
			/>

			<div
				id="home-banner"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "92.3vh",
					backgroundColor: "#128602",
					padding: "0px 20px",
				}}
			>
				<h1
					style={{
						fontFamily: "Manrope",
						fontSize: 96,
						fontWeight: "200",
						color: "#FFFFFF",
						textAlign: "center",
						width: "80%",
					}}
				>
					Let's Explore The Greatest Mountains with <span style={{ fontWeight: "600" }}>ROTA</span>
				</h1>

				<a
					href="#home-about"
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: 14,
						textDecoration: "none",
					}}
				>
					<p
						style={{
							fontFamily: "Manrope",
							fontWeight: "300",
							lineHeight: 1.4,
							fontSize: 40,
							color: "#FFFFFF",
							marginRight: 16,
						}}
					>
						Find out more
					</p>
					<img
						src={arrowDown}
						alt=""
						style={{
							width: 24,
							height: 34,
							animation: "fadeUpDown 2s infinite",
						}}
					/>
				</a>
			</div>

			<div
				id="home-about"
				style={{
					display: "flex",
					flexDirection: "row",
					padding: "220px 120px 100px 120px",
					zIndex: 2,
				}}
			>
				<div
					style={{
						position: "relative",
						top: 70,
					}}
				>
					<h1
						style={{
							fontFamily: "Manrope",
							fontWeight: "400",
							fontSize: 60,
						}}
					>
						About{" "}
						<span
							style={{
								fontFamily: "Manrope",
								fontWeight: "700",
							}}
						>
							ROTA
						</span>
					</h1>
					<p
						style={{
							fontFamily: "Manrope",
							fontWeight: "500",
							fontSize: 32,
							lineHeight: 1.5,
							marginTop: 14,
							width: "94%",
						}}
					>
						ROTA is an app that providing information on the path that has been traveled, signal availability, add
						marker points, and all of these features are features that can help you when you are lost.
					</p>
				</div>
				<div>
					<img
						src={appLogo}
						alt="app logo"
						style={{
							width: 375,
							height: 500,
						}}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					padding: "90px 120px 0px 120px",
					zIndex: 2,
				}}
			>
				<div
					style={{
						position: "relative",
					}}
				>
					<h1
						style={{
							fontFamily: "Manrope",
							fontWeight: "400",
							fontSize: 60,
							marginBottom: 40,
						}}
					>
						Our{" "}
						<span
							style={{
								fontFamily: "Manrope",
								fontWeight: "700",
							}}
						>
							Features
						</span>
					</h1>
					<div
						style={{
							backgroundColor: "#D0EDCD",
							width: "86%",
							borderRadius: 16,
							padding: "50px 60px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<img
								src={footStep}
								alt="track"
								style={{
									width: 40,
									height: 50,
								}}
							/>
							<img
								src={footStepGrey}
								alt="track"
								style={{
									width: 24,
									height: 34,
									position: "relative",
									top: 40,
								}}
							/>
						</div>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "800",
								fontSize: 36,
								lineHeight: 1.5,
								marginTop: 28,
							}}
						>
							Tracking Your Hiking Trail
						</p>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "500",
								fontSize: 24,
								lineHeight: 1.5,
								marginTop: 14,
								marginBottom: 60,
							}}
						>
							ROTA can create an online or offline trail of your journey also download map feature that will help you to
							know the route and Rota will be your companion while hiking.
						</p>
					</div>
				</div>
				<div>
					<img
						src={featureOne}
						alt="feature"
						style={{
							width: 700,
							height: 800,
						}}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					padding: "0px 120px 0px 120px",
					zIndex: 2,
				}}
			>
				<div
					style={{
						position: "relative",
						top: 70,
					}}
				>
					<div
						style={{
							backgroundColor: "#D0EDCD",
							width: "86%",
							borderRadius: 16,
							padding: "50px 60px",
						}}
					>
						<img
							src={signalIcon}
							alt="track"
							style={{
								width: 60,
								height: 50,
							}}
						/>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "800",
								fontSize: 36,
								lineHeight: 1.5,
								marginTop: 16,
							}}
						>
							Signal Information on Hiking Route
						</p>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "500",
								fontSize: 24,
								lineHeight: 1.5,
								marginTop: 14,
								marginBottom: 60,
							}}
						>
							Confused about where the previous signal spot was while hiking? We track your trails in online and offline
							mode! if there's a signal the trails will turns into blue and red that indicate absence of a signal.
						</p>
					</div>
				</div>
				<div>
					<img
						src={featureTwo}
						alt="feature"
						style={{
							width: 700,
							height: 800,
						}}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					padding: "0px 10px 100px 120px",
					zIndex: 2,
				}}
			>
				<div
					style={{
						position: "relative",
						top: 70,
					}}
				>
					<div
						style={{
							backgroundColor: "#D0EDCD",
							width: "86%",
							borderRadius: 16,
							padding: "50px 60px",
						}}
					>
						<img
							src={markenIcon}
							alt="track"
							style={{
								width: 50,
								height: 50,
							}}
						/>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "800",
								fontSize: 36,
								lineHeight: 1.5,
								marginTop: 16,
							}}
						>
							Mark the Hidden Gems!
						</p>
						<p
							style={{
								fontFamily: "Manrope",
								fontWeight: "500",
								fontSize: 24,
								lineHeight: 1.5,
								marginTop: 14,
								marginBottom: 60,
							}}
						>
							Found something interesting like water fall? animal habitats? two-way lanes?resting place? camping ground?
							Place your mark on your hiking journey!
						</p>
					</div>
				</div>
				<div>
					<img
						src={featureThree}
						alt="feature"
						style={{
							width: 800,
							height: 700,
						}}
					/>
				</div>
			</div>
		</div>
	);
}
