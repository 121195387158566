import React from "react";
import { useNavigate } from "react-router-dom";

import logoFooter from "../../assets/rota-footer-logo.png";
import appstoreLogo from "../../assets/appstore-logo.png";

function Footer() {
	const navigate = useNavigate();

	return (
		<div
			style={{
				backgroundColor: "#204A50",
				position: "relative",
				zIndex: 99,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "flex-start",
				padding: "30px 30px",
			}}
		>
			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<img
						src={logoFooter}
						alt="rota logo"
						style={{
							objectFit: "contain",
							width: 160,
							marginBottom: 20,
						}}
					/>
					<a href="https://apps.apple.com/us/app/rota-navigation/id6471991245" target="_blank" rel="noreferrer">
						<img
							src={appstoreLogo}
							alt="rota logo"
							style={{
								objectFit: "contain",
								width: 160,
							}}
						/>
					</a>
				</div>
			</div>

			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "end",
					}}
				>
					<p
						style={{
							fontFamily: "Manrope",
							color: "#FFFFFF",
							fontWeight: "400",
							fontSize: 16,
						}}
					>
						Molekul Baja Emas
					</p>
					<p
						style={{
							fontFamily: "Manrope",
							color: "#FFFFFF",
							fontWeight: "400",
							fontSize: 16,
						}}
					>
						South Tangerang, Indonesia
					</p>
					<p
						style={{
							fontFamily: "Manrope",
							color: "#C4C4C4",
							fontWeight: "400",
							fontSize: 14,
							marginTop: 12,
							marginBottom: 4,
						}}
					>
						<a
							href="mailto: molekulbajaemas@rotanavigation.com"
							style={{
								color: "#C4C4C4",
							}}
						>
							molekulbajaemas@rotanavigation.com
						</a>
					</p>
					<a
						href="/"
						onClick={e => {
							e.preventDefault();
							navigate("/privacy-policy");
						}}
						style={{
							fontFamily: "Manrope",
							color: "#C4C4C4",
							fontWeight: "400",
							fontSize: 14,
						}}
					>
						Privacy Policy
					</a>
					<p
						style={{
							fontFamily: "Manrope",
							color: "#C4C4C4",
							fontWeight: "700",
							fontSize: 12,
							textAlign: "center",
							marginTop: 20,
						}}
					>
						© 2023 Rota Navigation from Molekul Baja Emas
					</p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
