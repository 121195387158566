/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import checkPassword from "../../../utils/checkPassword";
import axiosInstance from "../../../utils/axiosInstance";

import "./index.css";

export default function ResetPasswordMobile() {
	const params = useParams();
	const { encryptedTokenAndId } = params;
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const handlePasswordChange = e => {
		setPassword(e.target.value);
		setIsPasswordValid(checkPassword(e.target.value));
	};

	const handleConfirmPasswordChange = e => {
		setConfirmPassword(e.target.value);
	};

	const isButtonEnabled = () => {
		if (isPasswordValid && password === confirmPassword && password !== "" && confirmPassword !== "") {
			return true;
		}

		return false;
	};

	const handleSubmit = async () => {
		try {
			if (!isButtonEnabled()) return;
			setLoading(true);

			await axiosInstance({
				method: "PATCH",
				url: `/users/reset/${encryptedTokenAndId}`,
				data: {
					password,
				},
			});

			setLoading(false);
			setSuccess(true);
			setPassword("");
			setConfirmPassword("");
		} catch (err) {
			console.log(err);
		}
	};

	if (loading) return null;

	if (success) {
		return (
			<div id="reset-password-mobile-container-success">
				<p>
					<b>Password reset success</b>
				</p>
				<p>Start using your account in our app</p>
				<br />
				<p>You can now close this tab</p>
			</div>
		);
	}

	return (
		<div id="reset-password-mobile-container">
			<div className="reset-password-mobile-container-header">
				<h3>Reset Password</h3>
			</div>

			<div className="reset-password-mobile-password-field">
				<input type="password" placeholder="Type your new password" value={password} onChange={handlePasswordChange} />
				<input
					type="password"
					placeholder="Re-type your new password"
					value={confirmPassword}
					onChange={handleConfirmPasswordChange}
				/>
				{!isPasswordValid && (
					<p className="reset-password-mobile-error-message">
						Password must be at least contain a capital letter, a number or symbol, and minimum of 6 characters
					</p>
				)}
			</div>

			<button
				onClick={handleSubmit}
				className={
					isButtonEnabled()
						? "reset-password-mobile-container-submit-button"
						: "reset-password-mobile-container-submit-button-disable"
				}
			>
				Submit
			</button>
		</div>
	);
}
