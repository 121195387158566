import React from "react";

function PrivacyPolicy() {
	return (
		<div
			style={{
				padding: "10px 60px",
			}}
		>
			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 40,
					fontWeight: "800",
					marginBottom: 20,
				}}
			>
				Privacy Policy
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				Welcome to Rota Navigation! This Privacy Policy is designed to help you understand how we collect, use,
				disclose, and safeguard your personal information. Please take the time to read this Privacy Policy carefully to
				ensure you are fully informed about our data practices.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				By using the Rota Navigation mobile application ("the App"), you consent to the practices described in this
				Privacy Policy.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				1. Information We Collect
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 12,
				}}
			>
				1.1. Personal Information:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				• <span style={{ fontWeight: "600" }}>Registration Data:</span> When you register and create an account with
				Rota Navigation, we collect personal information such as your name, email address, and password. You may also
				choose to provide additional information like a profile picture.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				• <span style={{ fontWeight: "600" }}>User-Generated Content:</span> While using the App, you may voluntarily
				share additional information, such as custom markers on the map and tracked journeys.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 12,
				}}
			>
				1.2. Location Information:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				• <span style={{ fontWeight: "600" }}>GPS Data:</span> The App uses GPS data to track and display your location
				on the map while using the tracking feature.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				2. How We Use Your Information
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				We use the information we collect for the following purposes:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>2.1. Providing Services:</span> To provide you with the core features of the
				App, including tracking routes and custom marker placement.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>2.2. Account Management:</span> To manage your account, including
				authentication, registration, and user profile settings.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>2.3. Communication:</span> To communicate with you about your account, app
				updates, and important service-related information.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				<span style={{ fontWeight: "600" }}>2.4. Personalization:</span> To improve your experience by providing
				personalized content and recommendations.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				3. Sharing and Disclosure of Information:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				We may share your information with the following:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>3.1. Other Users:</span> You have the option to share your tracked journeys
				and custom markers with other users of the App.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				<span style={{ fontWeight: "600" }}>3.2. Legal Requirements:</span> We may disclose information when required by
				law, in response to legal processes, or to protect our rights, privacy, safety, or property.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				4. Your Privacy Choices:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				You can exercise the following privacy choices:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>4.1. Account Information:</span> You can update or delete your account
				information through the App settings.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 14,
				}}
			>
				<span style={{ fontWeight: "600" }}>4.2. Location Services:</span> You can control location access through your
				device settings.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				<span style={{ fontWeight: "600" }}>4.3. Communication Preferences:</span> You can manage your communication
				preferences through the App settings.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				5. Data Security
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				We implement appropriate security measures to protect your information. However, please be aware that no data
				transmission over the internet is completely secure, and we cannot guarantee the security of your information.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				6. Contact Us
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 8,
				}}
			>
				If you have any questions or concerns about this Privacy Policy or our data practices, you can contact us at:
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				Email: <a href="mailto: molekulbajaemas@rotanavigation.com">molekulbajaemas@rotanavigation.com</a>
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "700",
					marginBottom: 20,
				}}
			>
				7. Changes to Privacy Policy
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				We may update this Privacy Policy as our services evolve. The most current version will be posted within the
				website. Please review it periodically for changes.
			</p>

			<p
				style={{
					fontFamily: "Manrope",
					fontSize: 18,
					fontWeight: "400",
					marginBottom: 20,
				}}
			>
				By using the Rota Navigation App, you agree to the terms and conditions of this Privacy Policy. If you do not
				agree with this Privacy Policy, please do not use the App.
			</p>
		</div>
	);
}

export default PrivacyPolicy;
