import React from "react";
import { useNavigate } from "react-router-dom";

import logoHeader from "../../assets/rota-header-logo.png";
import appstoreLogo from "../../assets/appstore-logo.png";

function HeaderMobile() {
	const navigate = useNavigate();

	return (
		<div
			style={{
				backgroundColor: "#FFFFFF",
				height: 60,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				paddingLeft: 20,
				paddingRight: 20,
				position: "sticky",
				top: 0,
				zIndex: 100,
			}}
		>
			<img
				onClick={() => {
					navigate("/");
				}}
				src={logoHeader}
				alt="rota-logo"
				style={{
					width: 93,
					height: 27,
				}}
			/>
			<a href="https://apps.apple.com/us/app/rota-navigation/id6471991245" target="_blank" rel="noreferrer">
				<img
					src={appstoreLogo}
					alt="rota-logo"
					style={{
						width: 90,
						height: 30,
					}}
				/>
			</a>
		</div>
	);
}

export default HeaderMobile;
