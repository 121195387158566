import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";

import "./index.css";

export default function ChangeEmail() {
	const params = useParams();
	const { encryptedTokenAndIdAndNewEmail } = params;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		const verifyNewEmail = async () => {
			try {
				await axiosInstance({
					method: "PATCH",
					url: `/users/email/${encryptedTokenAndIdAndNewEmail}`,
				});

				setLoading(false);
			} catch (err) {
				setError(true);
				setLoading(false);
			}
		};

		verifyNewEmail();
	}, [encryptedTokenAndIdAndNewEmail]);

	if (loading) return null;

	if (error) {
		return (
			<div id="change-email-container">
				<p>
					<b>Email already verified</b>
				</p>
				<p>Start using your account in our app</p>
				<br />
				<p>You can now close this tab</p>
			</div>
		);
	}

	return (
		<div id="change-email-container">
			<p>
				<b>Your new email is now verified</b>
			</p>
			<p>Start using your account with your new email</p>
			<br />
			<p>You can now close this tab</p>
		</div>
	);
}
