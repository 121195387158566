import React from "react";
import contourBg from "../../../assets/contour-bg.png";
import arrowDown from "../../../assets/arrow-down-icon.png";
import featureOne from "../../../assets/feature-one.png";
import featureTwo from "../../../assets/feature-two.png";
import featureThree from "../../../assets/feature-three.png";

import "./index.css";

export default function HomeMobile() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				overflowX: "hidden",
			}}
		>
			<img
				src={contourBg}
				alt="rota"
				style={{
					width: "100%",
					position: "fixed",
					top: 0,
					opacity: 0.1,
					pointerEvents: "none",
				}}
			/>

			<div
				id="home-mobile-banner"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					width: "100%",
					height: "80vh",
					backgroundColor: "#128602",
					padding: "0px 20px",
				}}
			>
				<h1
					style={{
						fontFamily: "Manrope",
						fontSize: 40,
						fontWeight: "200",
						color: "#FFFFFF",
					}}
				>
					Let's Explore The Greatest Mountains with <span style={{ fontWeight: "600" }}>ROTA</span>
				</h1>

				<a
					href="#home-mobile-about"
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: 14,
						textDecoration: "none",
						width: 150,
					}}
				>
					<p
						style={{
							fontFamily: "Manrope",
							fontWeight: "300",
							lineHeight: 1.4,
							fontSize: 18,
							color: "#FFFFFF",
							marginRight: 8,
						}}
					>
						Find out more
					</p>
					<img
						src={arrowDown}
						alt=""
						style={{
							width: 12,
							height: 16,
							animation: "fadeUpDown 2s infinite",
						}}
					/>
				</a>
			</div>

			<div
				id="home-mobile-about"
				style={{
					paddingLeft: 20,
					paddingRight: 20,
					paddingTop: 60,
					zIndex: 2,
				}}
			>
				<h1
					style={{
						fontFamily: "Manrope",
						fontWeight: "400",
						fontSize: 34,
					}}
				>
					About{" "}
					<span
						style={{
							fontFamily: "Manrope",
							fontWeight: "700",
						}}
					>
						ROTA
					</span>
				</h1>
				<p
					style={{
						fontFamily: "Manrope",
						fontWeight: "500",
						fontSize: 14,
						lineHeight: 1.5,
						marginTop: 14,
					}}
				>
					ROTA is an app that providing information on the path that has been traveled, signal availability, add marker
					points, and all of these features are features that can help you when you are lost.
				</p>
			</div>

			<div
				id="home-mobile-feature"
				style={{
					marginTop: 40,
					zIndex: 2,
				}}
			>
				<img
					src={featureOne}
					alt="feature one"
					style={{
						width: 390,
						height: 452,
						marginBottom: -40,
						pointerEvents: "none",
					}}
				/>
				<img
					src={featureTwo}
					alt="feature one"
					style={{
						width: 390,
						height: 388,
						marginBottom: -40,
						pointerEvents: "none",
					}}
				/>
				<img
					src={featureThree}
					alt="feature one"
					style={{
						width: 390,
						height: 388,
						pointerEvents: "none",
					}}
				/>
			</div>
		</div>
	);
}
