import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";

import "./index.css";

export default function VerifyAccount() {
	const params = useParams();
	const { encryptedId } = params;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		const verifyAccount = async () => {
			try {
				await axiosInstance({
					method: "PATCH",
					url: `/users/verify/${encryptedId}`,
				});

				setLoading(false);
			} catch (err) {
				setError(true);
				setLoading(false);
			}
		};

		verifyAccount();
	}, [encryptedId]);

	if (loading) return null;

	if (error) {
		return (
			<div id="verify-account-container">
				<p>
					<b>Account already verified</b>
				</p>
				<p>Start using your account in our app</p>
				<br />
				<p>You can now close this tab</p>
			</div>
		);
	}

	return (
		<div id="verify-account-container">
			<p>
				<b>Your account is now verified</b>
			</p>
			<p>Start using your account in our app</p>
			<br />
			<p>You can now close this tab</p>
		</div>
	);
}
