import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import ScrollToTop from "./hooks/ScrollToTop";

// header
import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";

// footer
import Footer from "./components/Footer";
import FooterMobile from "./components/FooterMobile";

// desktop route
import Home from "./pages/desktop/Home";
import VerifyAccount from "./pages/desktop/VerifyAccount";
import ResetPassword from "./pages/desktop/ResetPassword";
import ChangeEmail from "./pages/desktop/ChangeEmail";
import PrivacyPolicy from "./pages/desktop/PrivacyPolicy";

// mobile route
import HomeMobile from "./pages/mobile/HomeMobile";
import VerifyAccountMobile from "./pages/mobile/VerifyAccountMobile";
import ResetPasswordMobile from "./pages/mobile/ResetPasswordMobile";
import ChangeEmailMobile from "./pages/mobile/ChangeEmailMobile";
import PrivacyPolicyMobile from "./pages/mobile/PrivacyPolicyMobile";

function App() {
	if (isMobileOnly) {
		return (
			<BrowserRouter>
				<ScrollToTop>
					<HeaderMobile />
					<Routes>
						<Route path="/" element={<HomeMobile />} />
						<Route path="/users/verify/:encryptedId" element={<VerifyAccountMobile />} />
						<Route path="/users/reset/:encryptedTokenAndId" element={<ResetPasswordMobile />} />
						<Route path="/users/email/:encryptedTokenAndIdAndNewEmail" element={<ChangeEmailMobile />} />
						<Route path="/privacy-policy" element={<PrivacyPolicyMobile />} />
					</Routes>
					<FooterMobile />
				</ScrollToTop>
			</BrowserRouter>
		);
	}

	return (
		<BrowserRouter>
			<ScrollToTop>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/users/verify/:encryptedId" element={<VerifyAccount />} />
					<Route path="/users/reset/:encryptedTokenAndId" element={<ResetPassword />} />
					<Route path="/users/email/:encryptedTokenAndIdAndNewEmail" element={<ChangeEmail />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				</Routes>
				<Footer />
			</ScrollToTop>
		</BrowserRouter>
	);
}

export default App;
